<template>
    <v-container id="dashboard" fluid tag="section">
      <v-text-field class="mt-4" :label="$t('datatable.write_to_search')" outlined append-icon="mdi-magnify" v-model="search">
      </v-text-field>
  
      <v-data-table :headers="headers" :items="desserts" sort-by="phone" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title> {{ $t("Drawer.kafeelGift") }} </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="890px">
  
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" @click="editedIndex = -1 " dark class="mb-2" v-bind="attrs" v-on="on">
                  {{ $t("add_new") }}
                </v-btn>
              </template>
              <v-stepper v-model="e1">
                <v-stepper-header>
                  <v-stepper-step editable :complete="e1 > 1" v-if="editedIndex > -1" step="1" style="    color: #fff;">
                    {{formTitle}}
                  </v-stepper-step>
                  <v-stepper-step :complete="e1 > 1" v-else step="1" style="    color: #fff;">
                    {{formTitle}}
                  </v-stepper-step>
  
  
  
                </v-stepper-header>
  
                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-card class="mb-12" flat>
  
                      <v-form ref="form" v-model="valid" lazy-validation>
  
  
  
                        <v-card-text>
                          <v-container>
  
                            <v-row>
                                <!-- title -->
                              <v-col class="py-0" cols="12" sm="12" md="12">
                                <v-text-field v-model="editedItem.title.ar" :rules="messageRules" required
                                  :label="$t('datatable.title')+' '+$t('ar')" outlined>
                                </v-text-field>
                              </v-col>
  
                              <v-col class="py-0" cols="12" sm="12" md="12">
                                <v-text-field v-model="editedItem.title.en" 
                                  :label="$t('datatable.title')+' '+$t('en')" outlined>
                                </v-text-field>
                              </v-col>
                               <!-- detail -->
                              <v-col class="py-0" cols="12" sm="12" md="12">
                                <v-text-field v-model="editedItem.detail.ar" :rules="messageRules" required
                                  :label="' التفاصيل '+$t('ar')" outlined>
                                </v-text-field>
                              </v-col>
  
                              <v-col class="py-0" cols="12" sm="12" md="12">
                                <v-text-field v-model="editedItem.detail.en" 
                                  :label="' التفاصيل '+$t('en')" outlined>
                                </v-text-field>
                              </v-col>


  
                              <!-- <v-col class="py-0" cols="12" sm="12" md="12">
                                 <v-text-field v-model="editedItem.price" :rules="messageRules" required
                                  label="السعر" outlined>
                                </v-text-field>
                              </v-col> -->
  
  
                
                              <v-col class="py-0" cols="6" sm="12" md="6">
                                <v-text-field :label="$t('Drawer.choose_img')" @click='pickImg' v-model='imageName'
                                  prepend-icon='fas fa-paperclip'>
                                </v-text-field>
                                <input type="file" style="display: none" ref="image" accept="image/*"
                                  @change="onFilePicked">
                              </v-col>
  
  
  
                              <v-col class="py-0" cols="6" sm="6" md="6">
                                <v-img :src="imageUrl" height="150" v-if="imageUrl" />
                              </v-col>
  
                                <div v-if="show_loading">
  
                              
                                <v-progress-circular indeterminate color="#f77d1f"
                                                     :size="150">
                                </v-progress-circular>
                              </div>
  
  
  
                            </v-row>
                          </v-container>
                        </v-card-text>
  
                      </v-form>
                    </v-card>
  
                    <v-btn color="primary" :loading="loadSave" @click="save()">
                      {{$t("save")}}
                    </v-btn>
  
                    <v-btn text @click="close()">
                      {{$t("close")}}
                    </v-btn>
                  </v-stepper-content>
  
                  <v-stepper-content step="2">
                    <UploudeImages :vid="editedItem" :images="editedItem.images" />
  
                    <v-btn text color="red" @click="close()">
                      {{$t("exist")}}
                    </v-btn>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-dialog>
          </v-toolbar>
        </template>
  
        <template v-slot:[`item.actions`]="{ item }">
  
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <button class="ml-5" @click="editItem(item)" v-if="!item.isDeleted" v-bind="attrs" v-on="on">{{ $t("edit") }}  </button>
            </template>
            <span>{{ $t("edit") }} </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <button @click="deleteItem(item.id)" v-if="!item.isDeleted" v-bind="attrs" v-on="on">
                {{$t('Delete')}}</button>
            </template>
            <span>{{$t('Delete')}}</span>
          </v-tooltip>
  
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">{{ $t("Reloading") }}</v-btn>
        </template>
      </v-data-table>
  
    </v-container>
  
  
  </template>
  
  <script>
    import axios from "axios";
    import Swal from "sweetalert2";
  
    export default {
  
      name: "kafeelGift",
      data() {
        return {
          desserts: [],
          category: [],
          file_name: '',
  
          book_name: "",
          book_url: "",
          book_file: "",
  
  
          file: "",
          e1: 1,
          imagesUrl: '',
          imageName: '',
          imageUrl: '',
          img_old: '',
          imageFile: '',
          imagesName: '',
  
  
          image_url: '',
          dialog: false,
          loadSave: false,
          show_loading: false,
          x:this.$i18n.locale,

          editedIndex: -1,
          editedItem: {
            image: {
              image_name: ''
            },
            id: "",
            title: {
              ar: "",
              en: ""
            },
            detail: {
              ar: "",
              en: ""
            },
            price: "",
            
          },
  
  
       
             Url:"https://qurancenter.alkafeel.net/",
  
          right: null,
          vvv: {},
          items: [],
  
  
          valid: false,
          rules: {
            required: value => !!value || this.$t("required_field"),
          },
  
          message: '',
          select: [v => !!v || this.$t("selectq")],
          messageRules: [
            v => !!v || this.$t("valMessage")
          ],
  
        }
      },
      watch:{
            '$i18n.locale': function(newVal, oldVal) {
            
            this.x=newVal;
            this.get();  
  },  },
      methods: {
        get(){
                return this.headers= [{
                        text: '#ID',
                        align: "start",
                        value: "id"
                    },

                    {
                        text: this.$t('datatable.title'),
                        align: "start",
                        value: this.x == 'en' ? "title.en" : "title.ar"
                    },
                                       {
                        text: this.$t('Processes'),
                        value: "actions",
                        sortable: false
                    }
                ];
            },
        validate() {
          valid: true;
        },
        initialize() {
          this.loading = true;
          try {
            axios.get("kafeelGift", {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  authorizations: "Bearer " + this.$store.state.AdminInfo.token
                }
              }).then(res => {
                this.loading = false;
                this.desserts = res.data.data;
  
              })
              .catch(() => {
                this.loading = false;
              });
          } catch (e) {
            console.log(e);
          }
        },
        pickFile() {
          this.$refs.file.click()
        },
        onPickfile(e) {
  
          this.file = this.$refs.file.files[0];
          let file = e.target.files;
          this.file = file[0]
          this.file_name = file[0].name
        },
        editItem(item) {
          console.log(item);
          this.dialog = true;
          this.e1 = 1;
          this.editedIndex = this.desserts.indexOf(item);
          this.$store.dispatch("book_uploads", item);
          this.editedItem = Object.assign({}, item);
          this.dialog = true;
          this.book_file = '';
          this.book_name = '';
          this.img_old = ''
          this.imageName = ''
          this.imageFile =null,
          this.imageUrl = ''
  
          //   if (this.imageUrl[0] == null) {
  
          this.img_old = this.editedItem['image'];
          this.imageUrl = this.resUrl+'upload/kafeelGift/thumb_new/' + this.img_old;
               
  
        },
        close() {
          this.dialog = false;
          this.e1 = 1;
          this.loadSave=false;
          this.$store.dispatch("book_uploads", this.vvv);
  
  
          this.img_old = ''
          this.imageName = ''
          this.imageFile = ''
          this.imageUrl = ''
  
          this.editedItem = {
            image: {
              image_name: ''
            },
            id: "",
            title: {
              ar: "",
              en: ""
            },
            detail: {
              ar: "",
              en: ""
            },
            price: "",
            
  
          };
          this.$refs.form.resetValidation();
  
        },
  
        pickFilePdf() {
          this.$refs.file.click()
        },
        onPickFilePdf(e) {
          const files = e.target.files
          if (files[0] !== undefined) {
            this.book_name = files[0].name
            if (this.book_name.lastIndexOf('.') <= 0) {
              return
            }
            const fr = new FileReader()
            fr.readAsDataURL(files[0])
            fr.addEventListener('load', () => {
              this.book_url = fr.result
              this.book_file = files[0] // this is an image file that can be sent to server...
            })
          } else {
            this.book_name = ''
            this.book_file = ''
            this.book_url = ''
          }
  
        },
  
  
        pickImg() {
          this.$refs.image.click()
        },
        pickFiles() {
          this.$refs.images.click()
  
        },
        onFilePicked(e) {
          const files = e.target.files
          if (files[0] !== undefined) {
            this.imageName = files[0].name
            if (this.imageName.lastIndexOf('.') <= 0) {
              return
            }
            const fr = new FileReader()
            fr.readAsDataURL(files[0])
            fr.addEventListener('load', () => {
              this.imageUrl = fr.result
              this.imageFile = files[0]
            })
          } else {
            this.imageName = ''
            this.imageFile = ''
            this.imageUrl = ''
          }
        },
  
  
        deleteItem($id) {
  
          Swal.fire({
            title: this.$t('sure_process'),
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: this.$t('yes'),
            cancelButtonText: this.$t('no'),
          }).then(result => {
            if (result.value) {
              axios.delete("kafeelGift/" + $id, {
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    authorizations: "Bearer " + this.$store.state.AdminInfo.token
                  }
                })
                .then(() => {
                  this.initialize();
                  Swal.fire(this.$t('Successfully'), this.$t('done'), "success");
                })
                .catch(() => {
                  this.$swal.fire(this.$t('not_successful'), this.$t('not_done'), "error");
                });
            }
          });
        },
       
        save() {
  
          if (this.$refs.form.validate()) {
            this.loadSave = true;
  
            if (this.imageFile == '') {
              this.loadSave = false;
              this.$swal.fire({
                title: "يجب اختيار صوره",
                text: "",
                icon: "error",
                confirmButtonText: "اغلاق",
              });
  
            } else if (this.$refs.form.validate()) {
              if (this.editedIndex > -1) {
                this.loadSave = true;
  
                var update = {
  
                    translations: [{
                        title: this.editedItem.title.ar,
                        detail: this.editedItem.detail.ar,
                      },
                      {
                        title: this.editedItem.title.en,
                        detail: this.editedItem.detail.en,
  
                      }
                    ],
                    price: this.editedItem.price,
  
                }
                this.axios
                  .put("kafeelGift/update/" + this.editedItem.id, update, {
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                    },
                  })
                  .then(() => {
                  this.$refs.form.resetValidation();
  
                    if (this.imageFile != null) {
                      let formData = new FormData();
                      //  const AuthStr = 'Bearer ' + this.$store.state.AdminInfo.token;
                      formData.append('image', this.imageFile);
                      if (this.imageFile != null) {
                      axios.post('kafeelGift/uploadImage/' + this.editedItem.id,
                        formData, {
                          headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                    authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                                },


                          
                        }
                      ).then(() => {
                        this.img_old = ''
                        this.imageName = ''
                        this.imageFile = ''
                        this.imageUrl = ''
                        
                    })

                }
                    }
                    this.$refs.form.resetValidation();
                    this.close();
  
                    this.initialize();
  
                    Swal.fire({
                      title: "تم تعديل ",
                      text: "",
                      icon: "success",
                      confirmButtonText: "اغلاق",
                    });
                  })
                  .catch(() => {
                    this.loadSave = false;
  
                    Swal.fire({
                      title: "تاكد من ملى المعلومات",
                      text: "",
                      icon: "error",
                      confirmButtonText: "اغلاق",
                    });
                  });
              } else {
  
                if (this.imageFile == '') {
  
                  Swal.fire({
                    title: "يجب اختيار صوره",
                    text: "",
                    icon: "error",
                    confirmButtonText: "اغلاق",
                  });
  
                } else {
  
                  this.show_loading=true;
                  var savedate = {
  
                    translations: [{
                        title: this.editedItem.title.ar,
                        detail: this.editedItem.detail.ar,
  
                      },
                      {
                        title: this.editedItem.title.en,
                        detail: this.editedItem.detail.en,
  
                      }
                    ],
                   
                    price: this.editedItem.price,
              
  
                  }
  
                  axios
                    .post("kafeelGift", savedate, {
                        headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                    authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                                },
                    })
                    .then((res) => {
                      this.editedItem = res.data.data;
                      this.editedIndex = -1;
                      this.$refs.form.resetValidation();
  
                      if (this.imageFile != null) {
  
  
                        let formData = new FormData();
                        // const AuthStr = 'Bearer ' + this.$store.state.AdminInfo.token;
                        formData.append('image', this.imageFile);
  
                        axios.post('kafeelGift/uploadImage/' + this.editedItem.id,
                            formData, {
                              headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                    authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                                },
                            }
                          )
  
                          .then((res) => {
                          
                                  this.dialog = false;
                                  this.loadSave = false;
                                  this.img_old = ''
                                  this.imageFile = ''
                                  this.imageUrl = ''
                                  this.imageName = '';
                                  this.show_loading=false;
                                  this.close();
                                  this.initialize();
                                  Swal.fire({
                                  title: "تم الرفع ",
                                  text: "",
                                  icon: "success",
                                  confirmButtonText: "اغلاق",
                                  });
                          })
  
                      }
                    })
  
                    .catch((err) => {
                      err
  
                      this.loadSave = false;
  
                    });
                }
              }
            }
          }
        },
      },
      components: {
  
      },
      computed: {
        formTitle() {
          return this.editedIndex === -1 ? this.$t('add_new') : this.$t('update');
  
        },
      },
  
      created() {
        this.initialize();
        this.get();
      }
  
    }
  </script>
  <style>
  .v-overlay--active {
      z-index: 0 !important;
  }
  
  </style>